import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';


import Logo from '../Logo';
import Transition from '../Transition';
import ProductDropdown from '../ProductDropdown';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <header className="top-12 absolute w-full z-10">
        <div className="container flex flex-row justify-between items-center mx-auto lg:py-2 px-6 md:px-0">
          <div className="flex-1 py-4">
            <Link to="/">
              <div className="mr-3">
                <Logo />
              </div>
            </Link>
          </div>
          <div className="-mr-2 flex items-center lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              onClick={() => setIsOpen(!isOpen)}
            >
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div className="hidden lg:block lg:ml-10 lg:pr-4">
          <Link
              to="/usecases/testenv"
              className="px-8 text-lg text-white py-2 transition duration-300 ease-in-out hover:text-codestream"
            >
              Product
          </Link>
          <Link
              to="/features"
              className="px-8 text-lg text-white py-2 transition duration-300 ease-in-out hover:text-codestream"
            >
              Features
            </Link>
            <Link
              to="/pricing"
              className="px-8 text-lg text-white py-2 transition duration-300 ease-in-out hover:text-codestream"
            >
              Pricing
            </Link>
            <a
              href="https://docs.strio.app"
              className="px-8 text-lg text-white py-2 transition duration-300 ease-in-out hover:text-codestream"
            >
              Docs
            </a>
            <Link
                  className="text-base inline-flex items-center justify-center ml-4 px-8 py-3 border border-transparent leading-6 font-bold tracking-wide rounded-md text-white bg-codestream hover:bg-cloud focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  to="/contact"
                >
                  Get started
            </Link>
          </div>
        </div>
      </header>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="text-white absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
          <div className="rounded-lg shadow-md">
            <div className="bg-lownight rounded-lg shadow-xs overflow-hidden">
              <div className="pl-2 pr-5 py-4 flex items-center justify-between">
                <Logo />
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3">
              <Link
                  to="/features"
                  className="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-gray-100 focus:bg-gray-50 transition duration-150 ease-in-out"
                >
                  Features
                </Link>
              <Link
                  to="/usecases/testenv"
                  className="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-gray-100 focus:bg-gray-50 transition duration-150 ease-in-out"
                >
                  Environments for better testing
                </Link>
                <Link
                  to="/pricing"
                  className="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-gray-100 focus:bg-gray-50 transition duration-150 ease-in-out"
                >
                  Pricing
                </Link>
                <a
                  href="https://docs.strio.app"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-gray-100 focus:bg-gray-50 transition duration-150 ease-in-out"
                >
                  Docs
                </a>
                <Link
                  to="/contact"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-gray-100 focus:bg-gray-50 transition duration-150 ease-in-out"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default Header;
