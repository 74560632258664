import React from 'react';

const Logo = () => {
  return (
    <>
      <svg
        height="29"
        viewBox="0 0 242 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="title"
        role="img"
      >
        <title id="title">strio.</title>
        <path
          d="M110.259 25.4757C109.401 24.6609 108.393 23.9747 107.235 23.4171C106.077 22.8596 104.855 22.5808 103.568 22.5808C102.582 22.5808 101.66 22.7738 100.802 23.1598C99.9873 23.5458 99.5798 24.2105 99.5798 25.1541C99.5798 26.0547 100.03 26.698 100.931 27.084C101.874 27.47 103.375 27.8989 105.434 28.3707C106.635 28.628 107.836 28.9926 109.037 29.4644C110.28 29.9361 111.396 30.558 112.382 31.33C113.368 32.102 114.162 33.0455 114.762 34.1606C115.363 35.2757 115.663 36.6052 115.663 38.1492C115.663 40.165 115.256 41.8805 114.441 43.2958C113.626 44.6682 112.554 45.7833 111.224 46.6411C109.937 47.4989 108.479 48.1208 106.849 48.5068C105.22 48.8927 103.59 49.0857 101.96 49.0857C99.3439 49.0857 96.7707 48.6783 94.2402 47.8634C91.7527 47.0057 89.6726 45.7619 88 44.1321L93.9186 37.8919C94.8621 38.9212 96.0201 39.779 97.3925 40.4652C98.765 41.1514 100.223 41.4945 101.767 41.4945C102.625 41.4945 103.461 41.3015 104.276 40.9155C105.134 40.4866 105.563 39.7575 105.563 38.7282C105.563 37.7418 105.048 37.0127 104.019 36.5409C102.989 36.0692 101.381 35.5759 99.1938 35.0613C98.0787 34.8039 96.9637 34.4608 95.8486 34.0319C94.7335 33.6031 93.7256 33.0241 92.8249 32.295C91.9672 31.5659 91.2595 30.6652 90.702 29.593C90.1444 28.5208 89.8656 27.2342 89.8656 25.7331C89.8656 23.8031 90.2731 22.1733 91.088 20.8438C91.9028 19.4714 92.9536 18.3563 94.2402 17.4985C95.5269 16.6408 96.9422 16.0189 98.4862 15.6329C100.073 15.204 101.638 14.9896 103.182 14.9896C105.584 14.9896 107.922 15.3756 110.195 16.1475C112.511 16.8766 114.462 17.9917 116.049 19.4928L110.259 25.4757Z"
          fill="white"
        />
        <path
          d="M133.226 23.9326V36.1558C133.226 37.6568 133.504 38.7934 134.062 39.5654C134.662 40.2945 135.713 40.659 137.214 40.659C137.729 40.659 138.265 40.6162 138.822 40.5304C139.423 40.4446 139.916 40.3159 140.302 40.1444L140.431 47.8643C139.702 48.1216 138.78 48.3361 137.664 48.5076C136.549 48.722 135.434 48.8292 134.319 48.8292C132.175 48.8292 130.373 48.5719 128.915 48.0573C127.457 47.4997 126.278 46.7277 125.377 45.7413C124.519 44.712 123.897 43.5111 123.511 42.1387C123.125 40.7234 122.932 39.1579 122.932 37.4424V23.9326H117.786V16.0197H122.868V7.59216H133.226V16.0197H140.752V23.9326H133.226Z"
          fill="white"
        />
        <path
          d="M166.485 24.6392C166.014 24.5105 165.542 24.4248 165.07 24.3819C164.641 24.339 164.212 24.3175 163.784 24.3175C162.368 24.3175 161.167 24.5748 160.181 25.0895C159.237 25.6042 158.465 26.226 157.865 26.9552C157.307 27.6843 156.9 28.4777 156.643 29.3354C156.385 30.1932 156.257 30.9438 156.257 31.5871V48.0562H145.706V16.0186H155.871V20.6506H155.999C156.814 19.0208 157.972 17.6913 159.473 16.662C160.974 15.6326 162.711 15.118 164.684 15.118C165.113 15.118 165.52 15.1394 165.906 15.1823C166.335 15.2252 166.678 15.2896 166.936 15.3753L166.485 24.6392Z"
          fill="white"
        />
        <path
          d="M182.633 5.78991C182.633 6.60478 182.461 7.37676 182.118 8.10587C181.818 8.79208 181.389 9.3925 180.832 9.90716C180.317 10.4218 179.674 10.8293 178.902 11.1295C178.173 11.4297 177.401 11.5798 176.586 11.5798C174.87 11.5798 173.433 11.0223 172.275 9.90718C171.117 8.7492 170.538 7.37677 170.538 5.78991C170.538 5.01792 170.689 4.28882 170.989 3.60261C171.289 2.87351 171.718 2.25162 172.275 1.73696C172.833 1.2223 173.476 0.814869 174.205 0.51465C174.935 0.171545 175.728 0 176.586 0C177.401 0 178.173 0.150109 178.902 0.450337C179.674 0.750565 180.317 1.158 180.832 1.67265C181.389 2.18731 181.818 2.80919 182.118 3.53829C182.461 4.22451 182.633 4.97504 182.633 5.78992L182.633 5.78991ZM171.31 48.0563V16.0188H181.861V48.0563H171.31Z"
          fill="white"
        />
        <path
          d="M224.192 31.909C224.192 34.5252 223.72 36.9055 222.776 39.0499C221.833 41.1514 220.546 42.9527 218.916 44.4538C217.287 45.912 215.399 47.0485 213.255 47.8634C211.111 48.6783 208.816 49.0857 206.371 49.0857C203.97 49.0857 201.675 48.6783 199.488 47.8634C197.343 47.0485 195.456 45.912 193.827 44.4538C192.24 42.9527 190.975 41.1514 190.031 39.0499C189.087 36.9055 188.616 34.5252 188.616 31.909C188.616 29.2928 189.087 26.9339 190.031 24.8324C190.975 22.7309 192.24 20.951 193.827 19.4928C195.456 18.0346 197.343 16.9195 199.488 16.1475C201.675 15.3756 203.97 14.9896 206.371 14.9896C208.816 14.9896 211.111 15.3756 213.255 16.1475C215.399 16.9195 217.287 18.0346 218.916 19.4928C220.546 20.951 221.833 22.7309 222.776 24.8324C223.72 26.9339 224.192 29.2928 224.192 31.909ZM214.027 31.909C214.027 30.8797 213.855 29.8718 213.512 28.8854C213.169 27.8989 212.676 27.0412 212.033 26.3121C211.389 25.5401 210.596 24.9182 209.652 24.4464C208.709 23.9747 207.615 23.7388 206.371 23.7388C205.128 23.7388 204.034 23.9747 203.091 24.4464C202.147 24.9182 201.354 25.5401 200.71 26.3121C200.11 27.0412 199.638 27.8989 199.295 28.8854C198.995 29.8718 198.845 30.8797 198.845 31.909C198.845 32.9383 198.995 33.9462 199.295 34.9326C199.638 35.919 200.131 36.8197 200.775 37.6346C201.418 38.4066 202.211 39.0284 203.155 39.5002C204.098 39.972 205.192 40.2079 206.436 40.2079C207.68 40.2079 208.773 39.972 209.717 39.5002C210.66 39.0284 211.454 38.4066 212.097 37.6346C212.74 36.8197 213.212 35.919 213.512 34.9326C213.855 33.9462 214.027 32.9383 214.027 31.909Z"
          fill="white"
        />
        <path
          d="M242.14 42.3947C242.14 44.196 241.497 45.6971 240.21 46.898C238.967 48.0989 237.465 48.6993 235.707 48.6993C234.806 48.6993 233.949 48.5278 233.134 48.1846C232.362 47.8844 231.676 47.4555 231.075 46.898C230.475 46.3405 230.003 45.6971 229.66 44.968C229.317 44.196 229.145 43.3597 229.145 42.4591C229.145 41.6013 229.317 40.7864 229.66 40.0144C230.003 39.2424 230.475 38.5777 231.075 38.0201C231.676 37.4197 232.362 36.9479 233.134 36.6048C233.949 36.2617 234.806 36.0901 235.707 36.0901C237.465 36.0901 238.967 36.6906 240.21 37.8915C241.497 39.0923 242.14 40.5934 242.14 42.3947V42.3947Z"
          fill="white"
        />
        <rect x="5.69672" y="13.0838" width="56.968" height="5.6968" rx="2.8484" fill="white" />
        <rect x="14.2424" y="27.3258" width="14.242" height="5.6968" rx="2.8484" fill="white" />
        <rect x="34.1807" y="27.3258" width="8.5452" height="5.6968" rx="2.8484" fill="#8F93F9" />
        <rect x="45.5748" y="27.3258" width="25.6356" height="5.6968" rx="2.8484" fill="white" />
        <rect y="41.5678" width="56.968" height="5.6968" rx="2.8484" fill="white" />
      </svg>
    </>
  );
};

export default Logo;
