import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <div className="bg-lownight text-white pt-16 pb-8">
    <div className="container mx-auto py-16 px-3">
      <div className="flex flex-col md:flex-row mx-3">
        <div className="flex-1 px-4 md:pr-8">
          <h2 className="text-lg font-semibold">About Us</h2>
          <p className="mt-5">
            Strio, deployments for humans, is a service by Synalabs, a French Managed Service Provider.
          </p>
        </div>
        <div className="px-4 py-4">
          <h2 className="text-lg font-semibold">Important Links</h2>
          <ul className="mt-4 leading-loose">
            <li>
              <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/legal-info"> Legal Information </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
