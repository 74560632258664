import React from 'react';
import Helmet from 'react-helmet';

const SEO = ({ title, description, url }) => {
  const image = 'https://strio.app/banner.png';

  return (
    <Helmet>
      <title>{title} | Strio</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}

      <html lang="en" />
    </Helmet>

  );
};

export default SEO;
